import styled from 'styled-components';

const Container = styled.div`
  overflow: hidden;
  position: relative;
  margin: 64px 0 25px 0;

  .cta-container {
    .styles-container {
      width: fit-content;
    }
  }

  .cta-link {
    display: flex;
    align-items: center;
    padding: 10px 0;

    img {
      width: 40px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.02);
      cursor: pointer;
    }
  }

  .cta {
    p {
      margin: 0 0 0 15px;
      text-align: left;
    }
  }

  h1,
  h2,
  h3 {
    font-size: var(--font-size-h1);
    color: var(--darkest);
    max-width: 400px;
  }

  p {
    color: var(--darkest);
  }

  p {
    font-size: var(--font-size-20);
  }

  .featured-image {
    max-height: 585px;
    max-width: 645px;
    display: flex;
    align-items: center;
    position: relative;

    img,
    .video-main-container,
    .video-preview-container {
      max-height: 525px;
      z-index: 1;
    }

    @media (min-width: 575px) and (max-width: 2400px) {
      &:before {
        content: '';
        background-color: var(--darkest);
        height: calc(100% + 60px);
        position: absolute;
        width: 50vw;
        left: 200px;
      }
    }
  }

  @media (min-width: 2400px) {
    .video-main-container,
    .video-preview-container {
      max-height: 100% !important;
    }
  }

  @media (max-width: 1100px) {
    .featured-image {
      display: flex;
      align-items: center;
    }

    p {
      font-size: var(--font-size-16);
    }
  }

  @media (max-width: 991px) {
    margin: 64px 0 0 0;

    .cta-container {
      flex-direction: column;
      padding: 0;
      margin: 15px 0 0 -10px !important;

      .left {
        margin-bottom: 20px;
      }

      .left,
      .right {
        padding: 0;
      }

      .MuiGrid-item {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    p {
      font-size: var(--font-size-20);
    }

    .featured-image {
      &:before {
        width: 100vw;
      }
    }
  }

  @media (max-width: 575px) {
    text-align: center;
    margin-bottom: 0;

    h1,
    h2,
    h3 {
      font-size: var(--font-size-h3);
      max-width: 235px;
      margin-bottom: 25px;
    }

    .cta-link {
      margin: 0 auto;
      width: 215px;
    }

    .content-container {
      flex-direction: column-reverse;
    }

    .featured-image {
      margin-bottom: 40px;
      flex-direction: column;
    }
  }
`;

const InnerContainer = styled.div`
  max-width: 1252px;
  margin: 0 auto;
  padding: 30px 28px;

  @media (max-width: 991px) {
    padding: 0 28px 30px 28px;
  }

  @media (max-width: 575px) {
    padding-bottom: 0;
  }
`;

export { Container, InnerContainer };
