import React from 'react';
import { Col, Row } from 'components/Core/Grid';
import Styles from 'components/Styles';
import HyperLink from 'components/Core/HyperLink';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import WebsiteImage from 'components/WebsiteImage';

import { Container, InnerContainer } from './styles';

const OnboardingSection = ({ entries }) => {
  const getEntry = (id) => entries?.find((entry) => entry.templateId === id);

  return (
    <Container>
      <InnerContainer>
        <Row gutter={80} align="middle" justify="space-between" className="content-container">
          <Col xs={24} sm={12} className="copy">
            <ContentfulRichText className="min-sm" richText={getEntry('copy-title')} />
            <ContentfulRichText richText={getEntry('body')} />
            <Row gutter={{ xs: 20, sm: 40 }} className="cta-container">
              {['left', 'right'].map((position, index) => (
                <Col xs={12} className={`cta ${position}`} key={`col-${index}`}>
                  <HyperLink
                    href={getEntry(`${position}-cta-text-link`)?.url}
                    className="cta-link"
                    data-position="Titan Advisor - CTA"
                  >
                    <WebsiteImage {...getEntry(`${position}-cta-icon`)} fullResolution />
                    <Styles {...getEntry(`${position}-cta-text-link`)?.styles}>
                      <p>{getEntry(`${position}-cta-text-link`)?.buttonLabel}</p>
                    </Styles>
                  </HyperLink>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={24} sm={11} className="featured-image">
            <ContentfulRichText className="max-sm" richText={getEntry('copy-title')} />
            <WebsiteImage {...getEntry('featured-image')} fullResolution />
          </Col>
        </Row>
      </InnerContainer>
    </Container>
  );
};

export default OnboardingSection;
